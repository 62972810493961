import React from 'react';
import { Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import useStore from 'lib/zustand';

const ServiceWorkerUpdate = () => {
  const hasUpdateReady = useStore(state => state.hasSwUpdateReady);
  const show = hasUpdateReady;
  return (
    <Transition
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={show ?? false}>
      <div className="bg-kopirun-green px-4 pt-5 pb-4 sm:(p-6 pb-4)">
        <div className="sm:(flex items-start)">
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:(mx-0 h-10 w-10)">
            <ExclamationIcon className="text-white" aria-hidden />
          </div>
          <div className="mt-3 text-center sm:(mt-0 text-left)">
            <h3 className="text-lg leading-6 font-medium text-white">Website update</h3>
            <div className="mt-2">
              <p className="text-sm text-white">
                Our website has an update available. Please load the update to ensure the best
                possible experience.
              </p>
            </div>
          </div>
          <div className="px-4 py-3 object-right justify-self-end sm:(px-6 flex flex-row-reverse)">
            <button
              className="w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base text-white bg-gray-500 hover:bg-gray-600 sm:(ml-3 text-sm)"
              type="button"
              onClick={() => window.location.reload(true)}>
              Load
            </button>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default ServiceWorkerUpdate;
