import React from 'react';
import PropTypes from 'prop-types';

import Footer from 'components/Footer';
import Header from 'components/Header';
import ServiceWorkerUpdate from 'components/ServiceWorkerUpdate';

const Layout = props => {
  const { children, className } = props;
  return (
    <div
      className={`font-lato text-gray-900 relative bg-white overflow-hidden flex flex-col min-h-screen ${
        className || ''
      }`}>
      <ServiceWorkerUpdate />
      <Header />
      <main className="flex flex-grow scrollbar-fix">{children}</main>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

Layout.defaultProps = {
  className: ''
};

export default Layout;
