import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import KopiCatRound from 'assets/kopicat-round.svg';
import { graphql, Link, useStaticQuery } from 'gatsby';

const navigation = [
  {
    route: '/careers',
    title: 'Careers'
  },
  {
    route: '/faq',
    title: 'FAQ'
  },
  {
    route: '/contact',
    title: 'Contact Us'
  }
];

const Header = () => {
  // const [isExpanded, toggleExpansion] = useState(false);
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <Popover as="header" className="scrollbar-fix">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto relative pt-6 px-4 sm:px-6 lg:px-8">
            <nav
              aria-label="Global"
              className="relative flex items-center justify-between sm:h-10 lg:justify-start">
              <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <Link to="/">
                    <h1 className="flex items-center text-black-200 no-underline">
                      <img alt="KopiCatHeader" className="block w-12 mr-2" src={KopiCatRound} />
                      <span className="text-xl font-bold tracking-tight">
                        {site.siteMetadata.title}
                      </span>
                    </h1>
                  </Link>
                  <div className="-mr-2 flex items-center md:hidden">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Open main menu</span>
                      <MenuIcon aria-hidden="true" className="h-6 w-6" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                {navigation.map(link => (
                  <Link
                    key={link.title}
                    className="font-medium text-gray-500 hover:text-gray-900"
                    to={link.route}>
                    {link.title}
                  </Link>
                ))}
                {/* <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                        Log in
                      </a> */}
              </div>
            </nav>
          </div>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            show={open}>
            <Popover.Panel
              className="absolute top-0 z-10 inset-x-0 p-2 transition transform origin-top-right md:hidden"
              focus
              static>
              <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="px-5 pt-4 flex items-center justify-between">
                  <Link to="/">
                    <h1 className="flex items-center text-black-200 no-underline">
                      <img alt="KopiCatHeader" className="block w-12 mr-2" src={KopiCatRound} />
                      <span className="text-xl font-bold tracking-tight">
                        {site.siteMetadata.title}
                      </span>
                    </h1>
                  </Link>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close main menu</span>
                      <XIcon aria-hidden="true" className="h-6 w-6" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="px-2 pt-2 pb-3 space-y-1">
                  {navigation.map(link => (
                    <Link
                      key={`${link.title}-mobile`}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                      to={link.route}>
                      {link.title}
                    </Link>
                  ))}
                </div>
                {/* <a
                    href="#"
                    className="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100"
                  >
                    Log in
                  </a> */}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default Header;
