import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

const SEO = ({ description, lang, meta, keywords, title, path }) => {
  const { site } = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          description
          author
          siteURL
        }
      }
    }
  `);
  const siteUrl = site.siteMetadata.siteURL;
  const metaDescription = description || site.siteMetadata.description;

  const isIndexPage = title === 'KopiRun';
  const titleTemplate = isIndexPage ? site.siteMetadata.title : `%s | ${site.siteMetadata.title}`;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      meta={[
        {
          name: 'description',
          content: metaDescription
        },
        {
          name: 'url',
          content: `${siteUrl}${path}`
        },
        {
          property: 'image',
          content: '//cdn.kopirun.com/website/preview-icon.png'
        },
        {
          property: 'og:title',
          content: title
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:url',
          content: `${siteUrl}${path}`
        },
        {
          property: 'og:image',
          content: '//cdn.kopirun.com/website/preview-icon.png'
        },
        {
          property: 'og:image:alt',
          content: title
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author
        },
        {
          name: 'twitter:title',
          content: title
        },
        {
          name: 'twitter:description',
          content: metaDescription
        },
        {
          property: 'twitter:image',
          content: '//cdn.kopirun.com/website/preview-icon.png'
        }
      ]
        .concat(
          keywords.length > 0
            ? {
                name: 'keywords',
                content: keywords.join(', ')
              }
            : []
        )
        .concat(meta)}
      title={title}
      titleTemplate={titleTemplate}
    />
  );
};

SEO.defaultProps = {
  description: 'KopiRun',
  lang: 'en',
  keywords: [],
  meta: []
};

SEO.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired
};

export default SEO;
