import React, { useEffect } from 'react';

const facebookAppId = '108474547517548';
const attribution = 'biz_inbox';

const FacebookChat = () => {
  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        xfbml: true,
        version: 'v11.0'
      });
    };
    (function (d, s, id) {
      let js;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      // eslint-disable-next-line prefer-const
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  });
  return (
    <>
      <div id="fb-root" />
      <div attribution={attribution} className="fb-customerchat" page_id={facebookAppId} />
    </>
  );
};

export default FacebookChat;
