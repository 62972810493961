import React from 'react';
import { Link } from 'gatsby';

import FacebookChat from 'components/FacebookChat';

const navigation = [
  {
    route: '/terms',
    title: 'Terms and Conditions'
  },
  {
    route: '/privacy',
    title: 'Privacy Policy'
  },
  {
    route: '/contact',
    title: 'Contact Us'
  }
];

const Footer = () => (
  <footer className="p-4 scrollbar-fix">
    <div className="max-w-7xl mx-auto px-4 sm:px-6">
      <div className="flex justify-between items-center py-2">
        <div className="flex space-x-10 flex-1">
          <Link
            key="kopirun-main"
            className="text-xs font-bold text-black no-underline sm:text-sm"
            to="/">
            &#169; KopiRun {new Date().getFullYear()}
          </Link>
        </div>
        <nav className="flex space-x-4 items-center justify-end flex-1">
          {navigation.map(link => (
            <Link
              key={`${link.title}-footer`}
              className="text-xs mt-2 font-bold text-center text-black sm:text-sm hover:text-gray-900"
              to={link.route}>
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </div>
    <FacebookChat />
  </footer>
);

export default Footer;
